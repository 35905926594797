import type {KitAdminState} from '@dv/kitadmin/models';
import {UserRole} from '@dv/shared/roles';

export const STUNDEN_KONTINGENT_PARAMETER_GUELTIGKEIT = {
    name: 'base.voreinstellungen.stunden-kontingent-parameter-gueltigkeit',
    url: '/stunden-kontingent-parameter-gueltigkeit',
    data: {
        role: UserRole.ADMIN,
    },
} satisfies KitAdminState;
